<template>
    <nav class="grid grid-flow-row">
        <div class="relative border-t border-gray-100 first:border-none">
            <a class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text"
                href="/">ACCEUIL</a>
        </div>
        <div class="relative border-t border-gray-100 first:border-none"><a
                class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text"
                href="about_fr.html">DEVENEZ MARCHAND</a>
        </div>
        <div class="relative border-t border-gray-100 first:border-none"><a
                class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text"
                href="products_fr.html">PRODUITS</a>
        </div>
        <div class="relative border-t border-gray-100 first:border-none"><a
                class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text"
                href="hunts_fr.html">MAGNUM HUNTS</a>
        </div>
    </nav>
</template>