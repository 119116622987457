// Wait for the DOM to be fully loaded
import { createApp } from "vue";
import Nav from "./Nav.vue";
import NavFr from "./NavFr.vue";
import Mobnav from "./Mobnav.vue";
import MobnavFr from "./MobnavFr.vue";
import Footer from "./Footer.vue";
import FooterFr from "./FooterFr.vue";
import ContactTwo from "./ContactTwo.vue";
import ContactTwoFr from "./ContactTwoFr.vue";

document.addEventListener('DOMContentLoaded', (event) => {

    // mob menu toggle
    const menuToggle = document.getElementById('menuToggle');
    const mobmenu = document.getElementById('mobmenu');

    menuToggle.addEventListener('click', () => {
        mobmenu.classList.toggle('hidden');
    });

    const nav = createApp(Nav);
    nav.mount("#nav");

    const navfr = createApp(NavFr);
    navfr.mount("#navfr");

    const mobnav = createApp(Mobnav);
    mobnav.mount("#mobnav");

    const mobnavfr = createApp(MobnavFr);
    mobnavfr.mount("#mobnavfr");

    const footer = createApp(Footer);
    footer.mount("#footer");

    const footerfr = createApp(FooterFr);
    footerfr.mount("#footerfr");

    const contact = createApp(ContactTwo);
    contact.mount("#contact");

    const contactfr = createApp(ContactTwoFr);
    contactfr.mount("#contactfr");
});