<script>
import MagnumSvg from './img/MagnumMarketing_Black.svg'
</script>

<template>
    <div class="grid max-w-[1200px] px-4 mx-auto grid-cols-1 sm:grid-cols-3 lg:grid-cols-3 gap-8">
        <div class="">
            <div class="text-center p-4 md:p-6">
                
            </div>
        </div>

        <div class="overflow-hidden">
            <div class="midfooterlinks inner text-center p-4 md:p-6">
                <img class="mx-auto max-w-[300px] sm:max-w-full" :src="require('./assets/MagnumMarketing_Black.svg')" width="552" height="193" alt="Magnum" />
                <p class="text-center mb-3">
                    10310, Gouin Blvd. Est <br>Montreal, Quebec, Canada H1C 1B1
                </p>
                <p class="text-center">
                    <a href="tel:514-881-2080">Tel: (514) 881.2080</a><br>
                    <a class="font-bold" href="mailto:info@magnummarketing.net">Email: info@magnummarketing.net</a>
                </p>
            </div>
        </div>

        <div class="overflow-hidden">
            <div class="midfooterlinks p-4 md:p-6">

            </div>
        </div>
    </div>

</template>