<template>
    <nav class="grid grid-flow-row">
        <div class="relative border-t border-gray-200 first:border-none">
            <a class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text"
                href="/">HOME</a>
        </div>
        <div class="relative border-t border-gray-200"><a
                class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text"
                href="about.html">BECOME A DEALER</a>
        </div>
        <div class="relative border-t border-gray-200"><a
                class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text"
                href="products.html">PRODUCTS</a>
        </div>
        <div class="relative border-t border-gray-200"><a
                class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text grid w-full grid-flow-col items-center justify-between gap-2 px-4 py-5 text-text font-medium focus:outline-none md:px-6 lg:px-8 text-color-strong-text"
                href="hunts.html">MAGNUM HUNTS</a>
        </div>
    </nav>
</template>