<template>
    <nav class="ml-auto grid-flow-col items-center gap-8 xl:gap-6 justify-end md:grid">
        <a class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 flex font-semibold outline-none focus:outline-none whitespace-nowrap text-base lg:text-2xl text-white whitespace-nowrap"
            href="/">HOME</a>
        <a class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 flex font-semibold outline-none focus:outline-none whitespace-nowrap text-base lg:text-2xl text-white whitespace-nowrap"
            href="about.html">BECOME A
            DEALER</a>
        <a class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 flex font-semibold outline-none focus:outline-none whitespace-nowrap text-base lg:text-2xl text-white whitespace-nowrap"
            href="products.html">PRODUCTS</a>
        <a class="flex-row items-center group transition-colors duration-150 ease-in-out gap-2 flex font-semibold outline-none focus:outline-none whitespace-nowrap text-base lg:text-2xl text-white whitespace-nowrap"
            href="hunts.html">MAGNUM
            HUNTS</a>
    </nav>
</template>